@import "../common/Common.scss";
@import "../color/Colors.scss";
.footer {
  padding: 50px 100px 20px;
  background-color: $master-blue;
  .logo-wrapper {
    border-bottom: 1px solid #fff;
    padding-bottom: 30px;
    .logo {
      height: 60px;
      display: flex;
      justify-content: flex-start;
    }
    p {
      text-align: left;
      color: #fff;
      font-size: 21px;
      font-weight: 600;
      margin-top: 18px;
    }
  }
  .middle-footer-info {
    padding: 50px 0;
    border-bottom: 1px solid #fff;
    ul {
      margin: 0;
      padding: 0;
      li {
        text-align: left;
        list-style-type: none;
        padding: 10px 0;
        a {
          color: #fff;
          text-decoration: none;
          font-size: 18px;
        }
      }
    }
    .newsletter {
      max-width: 420px;
      float: right;
      p {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
      }
      .newslette-input-wrapper {
        position: relative;
        img {
          position: absolute;
          left: 10px;
          bottom: 13px;
        }
        input {
          background-color: #28acfd;
          color: #fff;
          border: 0;
          height: 50px;
          font-size: 17px;
          padding-left: 50px;
          width: 100%;
          border-radius: 5px;
          &::placeholder {
            color: #fff;
            font-size: 15px;
          }
        }
        .subscribe {
          background-color: $orange;
          color: #fff;
          position: absolute;
          right: 0;
          top: 0px;
          height: 50px;
          border: none;
          border-radius: 0px 5px 5px 0px;
          -moz-border-radius: 0px 5px 5px 0px;
          -webkit-border-radius: 0px 5px 5px 0px;
          border: 0px solid #000000;
        }
      }

      .social-wrapper {
        .social-link {
          float: left;
          text-align: left;
          margin: 40px 0px;
          ul.social {
            padding: 0;
            margin: 0;
            li {
              display: inline-flex;
              margin-right: 20px;
              a {
                img {
                  width: 35px;
                  height: 35px;
                }
              }
            }
          }
          .privacy {
            ul {
              padding: 0;
              margin: 0;
              li {
                display: inline-flex;
                &:first-child {
                  margin-right: 40px;
                }
                a {
                  color: #fff;
                  text-decoration: none;
                  font-size: 14px;
                  &:hover {
                    color: #fff;
                  }
                }
              }
            }
          }
        }
        .aus-logo {
          float: right;
          margin: 40px 0px;
          img.aus {
            width: 80px;
          }
        }
      }
    }
  }
  .cite {
    text-align: left;
    color: #fff;
    margin-top: 30px;
    span {
      margin-left: 50px;
    }
  }
}

@media (min-width: 0) and (max-width: 767px) {
  .footer {
    padding: 50px 20px 20px;
    .middle-footer-info {
      .newsletter {
        max-width: 100%;
        float: none;
        p {
          text-align: left;
          margin: 20px 0 0 0;
        }
        .newslette-input-wrapper {
          .custom-error {
            margin: -1px 0 0 0 !important;
            right: auto !important;
            border-radius: 0 !important;
          }
          position: relative;
          img {
            position: absolute;
            left: 10px;
            bottom: 13px;
          }
          input {
            background-color: #28acfd;
            color: #fff;
            border: 0;
            height: 50px;
            font-size: 17px;
            padding-left: 50px;
            &::placeholder {
              color: #fff;
              font-size: 15px;
            }
          }
          .subscribe {
            background-color: $orange;
            color: #fff;
            position: absolute;
            right: 0;
            top: 0px;
            height: 50px;
            border: none;
            border-radius: 0px 5px 5px 0px;
            -moz-border-radius: 0px 5px 5px 0px;
            -webkit-border-radius: 0px 5px 5px 0px;
            border: 0px solid #000000;
          }
        }

        .social-wrapper {
          .social-link {
            float: left;
            text-align: left;
            margin: 40px 0px;
            ul.social {
              padding: 0;
              margin: 0;
              li {
                display: inline-flex;
                margin-right: 20px;
                a {
                  img {
                    width: 35px;
                    height: 35px;
                  }
                }
              }
            }
            .privacy {
              ul {
                padding: 0;
                margin: 0;
                li {
                  display: inline-flex;
                  &:first-child {
                    margin-right: 40px;
                  }
                  a {
                    color: #fff;
                    text-decoration: none;
                    font-size: 14px;
                    &:hover {
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
          .aus-logo {
            float: right;
            margin: 40px 0px;
            img.aus {
              width: 80px;
            }
          }
        }
      }
    }
  }
}
