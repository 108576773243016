@font-face {
  font-family: "gBold";
  src: url("../src/fonts/Gotham.woff2") format("woff2"),
    url("../src/fonts/Gotham.woff") format("woff"),
    url("../src/fonts/Gotham.ttf") format("truetype"),
    url("../src/fonts/Gotham.svg#Gotham") format("svg");
}

@font-face {
  font-family: "Gotham";
  src: url("../src/fonts/Gotham-Medium.woff2") format("woff2"),
    url("../src/fonts/Gotham-Medium.woff") format("woff"),
    url("../src/fonts/Gotham-Medium.ttf") format("truetype"),
    url("../src/fonts/Gotham-Medium.svg#Gotham") format("svg");
}

@font-face {
  font-family: "gL";
  src: url("../src/fonts/Gotham-Light.woff") format("woff"),
    url("../src/fonts/Gotham-Light.ttf") format("truetype"),
    url("../src/fonts/Gotham-Light.svg#Gotham") format("svg"),
    url("../src/fonts/Gotham-Light.otf") format("otf");
}

@font-face {
  font-family: "gR";
  src: url("../src/fonts/Gotham-Regular.woff") format("woff"),
    url("../src/fonts/Gotham-Regular.ttf") format("truetype"),
    url("../src/fonts/Gotham-Regular.svg#Gotham") format("svg");
}

* {
  box-sizing: border-box;
  font-family: "Gotham", Arial, Helvetica, sans-serif !important;
}

.observer-datepicker .css-wb57ya-MuiFormControl-root-MuiTextField-root {
  width: 90%;
}

.observer-datepicker .tooltip {
  margin: 10px 0 0 4px;
  color: #fc9019;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  font-size: 13px !important;
  background-color: #fafafa;
  color: #fc9019;
  border: 1px solid #eee;
}

.left-wrapper {
  padding: 20px;
}

.login-info-wrapper {
  background: #071d33;
  border-radius: 14px;
  width: 100%;
  min-height: calc(100vh - 50px);
  padding: 20px;
  position: relative;
}

.login-info-wrapper h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 43px;
  color: #ffffff;
  margin: 50px 0 20px;
  padding: 0;
}

.login-info-wrapper h6 {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff;
  opacity: 0.8;
  padding: 0;
  margin: 0;
  font-family: "gL" !important;
}

.login-info-wrapper .news-info ul {
  padding: 0;
  margin: 0;
  margin-top: 50px;
  text-align: center;
}

.login-info-wrapper .news-info ul li {
  display: inline-flex;
  margin: 0 10px;
}

.login-info-wrapper .news-info ul li a {
  display: block;
  text-decoration: none;
  transition: transform 0.2s ease;
}

.login-info-wrapper .news-info ul li a:hover {
  transform: scale(1.05);
}

.login-info-wrapper .news-info ul li a .thumbnail {
  min-height: 250px;
  overflow: hidden;
  background-color: #fff;
  padding: 10px;
  border-radius: 15px;
  width: 170px;
}

.login-info-wrapper .news-info ul li a .thumbnail img {
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
}

.login-info-wrapper .news-info ul li a .thumbnail h2 {
  font-style: normal;
  font-weight: 350;
  font-size: 15px;
  display: flex;
  line-height: 17px;
  color: #071d33;
  text-align: left;
  font-family: "gL" !important;
}

.login-info-wrapper .news-info {
  margin-bottom: 65px;
}

.login-info-wrapper .support {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  background: #071d33;
  padding-bottom: 20px;
  border-radius: 14px;
}

.login-info-wrapper .support a {
  border: 1px solid #218ffc;
  color: #fff;
  border-radius: 10px;
  font-size: 11px;
  margin-left: 20px;
  padding: 15px 20px;
  font-family: "Gotham";
  text-decoration: none;
}

.main-login-right {
  padding: 80px;
}

.main-login-right .menu ul {
  padding: 0;
  margin: 0;
}

.main-login-right .menu ul li {
  display: inline-flex;
  margin: 20px;
  margin-top: 0;
  color: #071d33;
}

.main-login-right .menu ul li:first-child {
  margin-left: 0;
}

.main-login-right .menu ul li a {
  color: #071d33;
  text-decoration: none;
}

.main-login-right .menu ul li a:hover {
  text-decoration: none;
}

.main-login-right .title {
  color: #071d33;
  font-size: 40px;
  line-height: 48px;
  margin: 50px 0 0;
  font-weight: bold;
}

.main-login-right .sub-title {
  color: #071d33;
  font-size: 20px;
  margin-bottom: 50px;
}

.MuiBox-root{
  border-bottom: 0px !important;
}

.login-input-wrapper {
  max-width: 400px;
  margin-top: 50px;
}

.login-input-wrapper .input-text {
  margin-bottom: 20px;
}

.login-input-wrapper .login-btn {
  height: 45px;
  padding: 0;
  width: 200px;
  margin-bottom: 20px;
  border-radius: 15px !important;
  text-transform: capitalize !important;
  font-family: "Gotham" !important;
}

.login-input-wrapper .forget-password {
  font-weight: 400;
  padding: 0px 0 0 20px;
  margin-top: -11px;
}

.login-input-wrapper .login-register button {
  margin-top: 10px;
  text-transform: capitalize !important;
  font-family: "Gotham" !important;
}

.login-input-wrapper .login-register button:hover {
  background-color: transparent;
}

.login-register-btn {
  text-transform: capitalize !important;
  font-family: "Gotham" !important;
  padding: 20px 25px !important;
  width: 200px;
  border-radius: 15px !important;
  font-size: 14px !important;
}

.sign-up-register-image img {
  width: 100%;
  max-width: 300px;
}

.requirement {
  padding: 50px 0;
  margin: 0 auto;
}

.requirement h2 {
  text-align: center;
  font-weight: 400;
  font-size: 42px;
  line-height: 50px;
  color: #071d33;
}

.requirement h6 {
  text-align: center;
  font-family: "gL";
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #071d33;
  opacity: 0.5;
  margin-bottom: 50px;
}

.requirement .custom_row {
  max-width: 900px;
  margin: 0 auto;
}

.requirement .thumbnail {
  background-color: #071d33;
  margin: 15px 0;
  width: 100%;
  border-radius: 72px;
  position: relative;
}

.requirement .thumbnail .wrapper {
  padding: 30px 20px;
  color: #fff;
}

.requirement .thumbnail .wrapper img {
  position: absolute;
  left: 15px;
  width: 48px;
  height: 48px;
  top: 15px;
}

.requirement .thumbnail .wrapper h5 {
  padding-left: 50px;
  margin: 0;
  font-weight: 350;
  font-size: 14px;
  line-height: 18px;
}

.makeMoney {
  background-color: #23c123;
  text-align: center;
  border-radius: 40px;
  max-width: 390px;
  padding: 15px 0;
  border-radius: 54px;
  margin: 50px auto 0;
}

.makeMoney .downloadAppWrapper {
  display: inline-flex;
  align-items: center;
}

.makeMoney img {
  width: 48px;
  height: 48px;
  margin-right: 10px;
}

.makeMoney span.title {
  font-size: 16px;
  color: #fff;
}

@media (min-width: 900px) and (max-width: 991px) {
  .main-login-right {
    padding: 40px 5px;
  }

  .main-login-right .title {
    font-size: 20px;
  }

  .main-login-right .sub-title {
    font-size: 10px;
  }

  .login-tab-bar .css-16v7qiq-MuiButtonBase-root-MuiTab-root {
    font-size: 11px;
  }

  .login-info-wrapper h1 {
    font-size: 24px;
    line-height: 29px;
  }

  .login-info-wrapper h6 {
    font-size: 13px;
    line-height: 17px;
  }

  .login-info-wrapper .news-info ul li {
    margin: 0 3px;
  }

  .login-info-wrapper .news-info ul li a .thumbnail {
    width: 146px;
  }

  .login-info-wrapper .news-info ul li a .thumbnail h2 {
    font-size: 12px;
  }
}

@media (min-width: 992px) and (max-width: 1003px) {
  .login-info-wrapper .news-info ul li a .thumbnail {
    width: 167px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .main-login-right {
    padding: 80px 10px;
  }

  .main-login-right .title {
    font-size: 30px;
  }

  .main-login-right .sub-title {
    font-size: 15px;
  }

  .login-tab-bar .css-16v7qiq-MuiButtonBase-root-MuiTab-root {
    font-size: 13px;
  }
}

@media (max-width: 899px) {
  .login-info-wrapper {
    min-height: 0;
  }

  .news-info {
    display: none;
  }

  .support {
    display: none;
  }

  .login-info-wrapper h1 {
    margin: 10px 0;
    font-size: 21px;
    line-height: 22px;
    text-align: center;
  }

  .login-info-wrapper h6 {
    display: none;
  }

  .main-login-right {
    padding: 10px 25px;
  }
}

@media (min-width: 350px) and (max-width: 615px) {
  .login-info-wrapper h1 {
    line-height: 30px;
    font-size: 17px;
  }

  .main-login-right .title {
    font-size: 21px;
    margin: 15px 0 0;
  }

  .main-login-right .sub-title {
    font-size: 13px;
  }

  .login-tab-bar .css-16v7qiq-MuiButtonBase-root-MuiTab-root {
    font-size: 10px;
    padding: 15px 12px 10px;
  }
}

.login-page-social-media {
  position: absolute;
  top: 20%;
  right: 0;
  background: #ffffff;
  box-shadow: 0px 0px 12px -2px rgb(33 143 252 / 25%);
  border-radius: 21px 0px 0px 21px;
  padding: 20px;
}

.login-page-social-media ul {
  margin: 0;
  padding: 0;
}

.login-page-social-media ul li {
  list-style: none;
  margin: 5px 0;
}

.login-page-social-media ul li a:hover img {
  transform: scale(1.2);
}

.login-page-social-media ul li a img {
  width: 25px;
  height: 25px;
  transition: transform 0.2s ease;
}

.remaing-time {
  text-align: center;
  letter-spacing: 2px;
  font-size: 20px;
  margin: 0;
  padding: 0;
}

.remaing-time.green {
  color: #23c123;
}

.remaing-time.orange {
  color: #fc9019;
}

.remaing-time.red {
  color: red;
}

.personal-detail-dob .css-1xkvp8t-MuiSvgIcon-root.tooltip {
  position: absolute;
  right: -25px;
  top: 12px;
}

h4.personal-detail {
  font-weight: 100;
  font-size: 16px;
  padding: 0 15px;
}

.personal-detail-list ul {
  margin: 0;
}

.personal-detail-list ul li {
  display: inline-block;
  margin-left: 100px;
}

.personal-detail-list h4 {
  font-weight: 100;
  font-size: 16px;
  padding: 0 15px;
  margin-top: 0;
}

.personal-detail-file-upload .file-upload {
  padding: 15px;
  margin-top: 20px;
  padding-bottom: 0;
}

.personal-detail-file-upload h4 {
  margin: 0;
  text-align: center;
  color: #23c124;
}

.personal-detail-file-upload .file-upload .css-vw4xk8 {
  width: 96px;
  display: block;
  margin: 0 auto;
  overflow: hidden;
}

.personal-detail-file-upload .file-upload .css-vw4xk8 input {
  font-size: 15px;
}

.personal-detail-file-upload
  .file-upload
  input[type="file"]::file-selector-button {
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  transition: 1s;
  cursor: pointer;
  margin: 0;
  border: 2px solid #e0e0e0;
  background-color: #e0e0e0;
}

.personal-detail-file-upload.uploaded
  .file-upload
  input[type="file"]::file-selector-button {
  border: 2px solid #23c123;
  background-color: #23c123;
}

.personal-detail-file-upload h4 {
  display: none;
}

.personal-detail-file-upload.uploaded h4 {
  display: block;
}

.personal-detail-file-upload.error-file
  .file-upload
  input[type="file"]::file-selector-button {
  border: 2px solid #fc9019;
  background-color: #fc9019;
}

.personal-detail-avatar {
  width: 150px;
  height: 150px;
  position: relative;
}

.sucess-dialog-dilog .MuiPaper-root {
  background-color: #c0d4c0;
}

@media (max-width: 480px) {
  .personal-detail-list ul li {
    margin-left: 0px;
  }
}

.public-liability-insurance-detail {
  background: #ffaaaa;
  border-radius: 5px;
  padding: 10px;
  -webkit-box-flex-wrap: nowrap !important;
  -webkit-flex-wrap: nowrap !important;
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
  flex-direction: row !important;
}
.public-liability-insurance-detail .public-liability-insurance-detail-anker {
  color: #ff4800;
}

.whats-app-live-chat{
  position: fixed;
  right: -12px;
  background: transparent;
  border: 0px;
  width: 50px;
  height: 180px;
  cursor: pointer;
  transition: right 0.3s ease; 
  top: 10%;
}
.whats-app-live-chat:hover{
  right: -9px;
}

.ncc-id-verification .ncc-radio{
  margin: 20px auto 10px;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.ncc-id-verification .ncc-radio label{
  margin: 5px 0;
}

.ncc-id-verification .ncc-radio .MuiFormGroup-root{
  flex-direction: row;
}

.ncc-id-verification .id-verification-file-upload {
  display: flex;
  align-items: center;
}
.ncc-id-verification .id-verification-file-upload  p{
  margin-right: 20px;
}

.ncc-id-verification .police-check-upload-status{
  font-size: 12px;
  color: #23c123;
}