@import "../color/Colors.scss";
.btn.submit-btn {
  width: 160px;
  height: 50px;
  font-family: "gBold";
  font-size: 18px;
  background-color: $master-blue;
  color: #fff;
  &:hover {
    background-color: $master-blue !important;
    color: #fff !important;
  }
  &:disabled {
    background-color: $master-blue !important;
    color: #fff !important;
  }
  &:focus {
    background-color: $master-blue !important;
    color: #fff !important;
  }
  &:active {
    background-color: $master-blue !important;
    color: #fff !important;
  }
}

.input-wrapper {
  position: relative;
  input {
    height: 50px;
    font-size: 20px;
  }
  label {
    font-size: 15px;
  }
}
.alert.alert-danger.custom-error {
  text-align: center !important;
  font-size: 12px !important;
  padding: 4px !important;
  position: absolute;
  left: 0;
  background-color: #c81e1e;
  color: #fff;
  border-radius: 5px;
}

.modal-content {
  margin-top: 150px;
}

.spinner-outer-block {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 72%);
  z-index: 999999;
  .spinner-block {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .spinner-border {
    border-right-color: #fff;
    span {
      color: #fff;
      font-size: 24px;
    }
  }
}
.info-wrapper {
  display: flex;
  flex-direction: column;
}
.inside-info-wrapper {
  display: flex;
}
.signature-invite-code {
  position: relative;
  &:hover::after {
    position: absolute;
    content: "Please enter your Invite Code if you have been invited by an existing Bearer user or during a campaign";
    top: 50px;
    right: 0;
    left: 0;
    padding: 10px;
    font-weight: 300;
    line-height: 15px;
    font-size: 13px !important;
    background-color: #fafafa;
    color: #fc9019;
    border: 1px solid #eee;
    z-index: 999;
  }
}